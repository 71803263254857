import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { hostname, parseError } from "./utils";

export default ({
  open,
  onClose,
  getToken,
  eventListener,
}: {
  open: boolean;
  onClose: () => void;
  getToken: Function;
  eventListener: Function;
}) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [blogArticleCount, setBlogArticleCount] = useState(8);
  const [blogStructure, setBlogStructure] = useState("category");
  const [dialogLoading, setDialogLoading] = useState(false);
  const [keywords, setKeywords] = useState<string[]>([]);
  const [response, setResponse] = useState<any>({ sections: [] });
  const [location, setLocation] = useState("");

  const steps = ["General", "Structure", "Overview"];

  const handleNext = async () => {
    if (activeStep === 0) {
      await handleSubmitBlog();
    } else if (activeStep === 2) {
      await handleSubmitFinished();
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const handleSubmitFinished = async () => {
    console.log("Submitting for final");
  };

  const handleSubmitBlog = async () => {
    const currentToken = await getToken();
    setDialogLoading(true);
    axios
      .post(
        `${hostname}/blogs/structure`,
        {
          blogStructure,
          keywords,
          location,
          blogArticleCount,
        },
        {
          headers: {
            Authorization: `Bearer ${currentToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
        setResponse(res.data);
        setActiveStep(activeStep + 1);
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      })
      .finally(() => {
        setDialogLoading(false);
      });
    console.log("Submit blog", blogArticleCount, blogStructure, keywords);
  };

  const GetLoadingIcon = () => {
    return dialogLoading ? <CircularProgress size={20} /> : <div></div>;
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Create Entire Blog</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Choose options for a generating an entire blog.
        </DialogContentText>
        <Stepper activeStep={activeStep} sx={{ mt: 2 }}>
          <Step>
            <StepLabel>General</StepLabel>
          </Step>
          <Step>
            <StepLabel>Structure</StepLabel>
          </Step>
          <Step>
            <StepLabel>Overview</StepLabel>
          </Step>
        </Stepper>
        {activeStep === 0 && (
          <Box sx={{ mt: 4 }}>
            <Typography sx={{ mt: 2 }} gutterBottom>
              Number of articles: {blogArticleCount}
            </Typography>
            <Slider
              defaultValue={8}
              aria-label="Small"
              valueLabelDisplay="auto"
              onChange={(e: any) => setBlogArticleCount(e.target.value)}
            />
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel>Structure</InputLabel>
              <Select
                value={blogStructure}
                label="Structure"
                onChange={(e) => setBlogStructure(e.target.value)}
              >
                <MenuItem value={"category"}>Category &gt; Article</MenuItem>
                <MenuItem value={"location"}>Location &gt; Article</MenuItem>
                <MenuItem value={"location_service"}>
                  Location &gt; Service &gt; Article
                </MenuItem>
                <MenuItem value={"location_service_category"}>
                  Location &gt; Service &gt; Category &gt; Article
                </MenuItem>
                <MenuItem value={"service_category"}>
                  Service &gt; Category &gt; Article
                </MenuItem>
                <MenuItem value={"service_location_category"}>
                  Service &gt; Location &gt; Category &gt; Article
                </MenuItem>
              </Select>
            </FormControl>
            <Autocomplete
              multiple
              freeSolo
              filterSelectedOptions
              sx={{ mt: 2 }}
              options={keywords}
              getOptionLabel={(option: string) => option}
              onChange={(e, v) => setKeywords(v)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Keywords"
                  placeholder="Add SEO Keywords"
                  helperText="Click enter to add keyword to list"
                />
              )}
            />
            <TextField
              sx={{ mt: 2 }}
              label="Location"
              value={location}
              helperText="Enter a location to better target local SEO keywords"
              onChange={(e: any) => setLocation(e.target.value)}
            />
          </Box>
        )}
        {activeStep === 1 && (
          <Box sx={{ mt: 4 }}>
            <React.Fragment>
              <Typography sx={{ ml: 2 }}>{response.blogName}</Typography>
              {response.pages.map((page: any) => (
                <React.Fragment key={page.pageName}>
                  <Typography sx={{ ml: 4 }}>{page.pageName}</Typography>
                  {page.sections.map((section: any) => (
                    <React.Fragment key={section.sectionName}>
                      <Typography sx={{ ml: 6 }}>
                        {section.sectionName}
                      </Typography>
                      <Box sx={{ ml: 8 }}>
                        <ul>
                          {section.articleTitles.map((title: string) => (
                            <li key={title}>{title}</li>
                          ))}
                        </ul>
                      </Box>
                    </React.Fragment>
                  ))}
                </React.Fragment>
              ))}
            </React.Fragment>
          </Box>
        )}
        {activeStep === 2 && <Box sx={{ mt: 4 }}>Step 3</Box>}
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onClose} sx={{ mt: 2 }}>
          Cancel
        </Button>
        <Button
          variant="text"
          onClick={handleSubmitBlog}
          sx={{ mt: 2 }}
          startIcon={<GetLoadingIcon />}
        >
          {activeStep === steps.length - 1 ? "Finish" : "Next"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
