import { Firestore } from "@firebase/firestore";
import Add from "@mui/icons-material/Add";
import AddPhotoAlternateOutlined from "@mui/icons-material/AddPhotoAlternateOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopyOutlined";
import Description from "@mui/icons-material/Description";
import DescriptionOutlined from "@mui/icons-material/DescriptionOutlined";
import ExpandMore from "@mui/icons-material/ExpandMore";
import SellIcon from "@mui/icons-material/Sell";
import SendIcon from "@mui/icons-material/Send";
import SourceIcon from "@mui/icons-material/Source";
import TuneIcon from "@mui/icons-material/Tune";
import UploadFileOutlined from "@mui/icons-material/UploadFileOutlined";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import axios from "axios";
import { User } from "firebase/auth";
import moment from "moment";
import React, { useEffect, useReducer, useRef, useState } from "react";
import { CopyBlock, dracula } from "react-code-blocks";
import ReactMarkdown from "react-markdown";
import { useNavigate } from "react-router-dom";
import remarkGfm from "remark-gfm";
import ChatLog from "./ChatLog";
import IconMenuItem from "./IconMenuItem";
import LoadingScreen from "./LoadingScreen";
import ModelSelector from "./ModelSelector";
import SelectEmployeeDialog from "./SelectEmployee";
import capture from "./capture";
import ChatApi from "./chatApi";
import {
  modelsLabelsList,
  supportedLanguages,
  visionModels,
} from "./constants";
import { Company, Document, Employee, Memory, Message } from "./types";
import {
  countWords,
  fileToBase64,
  hostname,
  parseError,
  uniqueId,
} from "./utils";

const listReducer = (state: Array<Message>, action: any) => {
  switch (action.type) {
    case "SET_LIST":
      return action.list;
    case "ADD_ITEM":
      return state.concat(action.item);
    case "UPDATE_ITEM":
      return state.map((x: Message) => {
        if (x.id === action.message.id) {
          return action.message;
        }
        return x;
      });
    case "APPEND_TEXT":
      return state.map((x: Message) => {
        if (x.id === action.id) {
          return {
            ...x,
            text: x.text + action.text,
          };
        }
        return x;
      });
    case "UPDATE_CONTEXT":
      return state.map((x: Message) => {
        if (x.id === action.id) {
          return {
            ...x,
            context: action.context,
          };
        }
        return x;
      });
    default:
      throw new Error();
  }
};

export interface SimpleDialogProps {
  open: boolean;
  message: Message | undefined;
  onClose: (value: Message | undefined) => void;
}

export default ({
  db,
  currentUser,
  id,
  companyId,
  token,
  getToken,
  eventListener,
  mode,
}: {
  db: Firestore;
  currentUser: any | User;
  id: string;
  companyId: string;
  token: any | string;
  getToken: Function;
  eventListener: Function;
  mode: string;
}) => {
  const [prompt, setPrompt] = useState("");
  const [chat, dispatchChatData] = useReducer(listReducer, []);
  const [employees, setEmployees] = useState<Array<Employee>>([]);
  const [employee, setEmployee] = useState<Employee>({
    uid: "",
    id: "",
    name: "",
    title: "",
    companyId: "",
    prompt: "",
    tags: [],
    picture: "",
  });
  const [promptType, setPromptType] = useState("chat");
  const [company, setCompany] = useState<Company>({
    uid: "",
    id: "",
    name: "",
    type: "",
    description: "",
    website: "",
    employees: [],
  });
  const [currentPlan, setCurrentPlan] = useState("free");
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const [init, setInit] = useState(false);
  const [recording, setRecording] = useState(false);
  const [documents, setDocuments] = useState<Array<Document>>([]);
  const [loading, setLoading] = useState(true);
  const [expanded, setExpanded] = useState<string | false>(false);
  const [selectEmployeesOpen, setSelectEmployeesOpen] = useState(false);
  const chatLogRef = useRef();
  const [contextSettings, setContextSettings] = useState({
    model: modelsLabelsList[0].name,
    memoryScope: "chat",
    searchEngine: false,
    shortTermMemories: true,
    longTermMemories: true,
    documents: true,
    company: true,
    employee: true,
    strategy: "one-shot",
    websites: false,
    websitesJs: false,
    documentScope: "tags",
    documentPath: "/",
    documentStrategy: "similarity-ranking",
  });
  const [dialogSelectedMessage, setDialogSelectedMessage] = useState<Message>();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [folderPath, setFolderPath] = useState("/");
  const [image, setImage] = useState("");
  const [requestDocuments, setRequestDocuments] = useState<Array<any>>([]);
  const navigate = useNavigate();
  const chatApi = new ChatApi();
  const theme = useTheme();
  chatApi.init(eventListener, getToken);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  function handleAdd(item: Message) {
    dispatchChatData({ type: "ADD_ITEM", item: item });
  }

  function setChat(list: Array<Message>) {
    dispatchChatData({ type: "SET_LIST", list: list });
  }

  function handleAppendText(id: number, text: string) {
    dispatchChatData({ type: "APPEND_TEXT", id: id, text: text });
  }

  function getChatIndex(index: number) {
    dispatchChatData({ type: "GET_INDEX", index: index });
  }

  function updateMessage(message: Message) {
    dispatchChatData({ type: "UPDATE_ITEM", message: message });
  }

  function updateContext(id: string, context: any) {
    dispatchChatData({ type: "UPDATE_CONTEXT", context: context, id: id });
  }

  function getList() {
    dispatchChatData({ type: "GET_LIST" });
  }

  const scrollToBottom = () => {
    if (messagesEndRef.current && messagesEndRef.current.scrollIntoView) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const getMessageAlign = (role: String) => {
    return role !== "user" ? "left" : "right";
  };

  const getEmployees = async (employeeId: string) => {
    const currentToken = await getToken();
    await axios
      .get(`${hostname}/companies/${companyId}/employees`, {
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
      })
      .then((res) => {
        setEmployees(res.data.employees);
        setEmployee(res.data.employees.find((x: any) => x.id === employeeId));
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      });
  };

  const createChat = async (employee: Employee) => {
    capture("Created new chat", {});
    const currentToken = await getToken();
    const chatId = uniqueId();
    await axios
      .post(
        `${hostname}/chats`,
        {
          ...employee,
          id: chatId,
          companyId: companyId,
          ts: new Date().getTime(),
          employeeId: employee.id,
        },
        {
          headers: {
            Authorization: `Bearer ${currentToken}`,
          },
        }
      )
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      });
    if (chatLogRef && chatLogRef.current) {
      // @ts-ignore
      chatLogRef.current.refreshChats();
    }
    navigate("/dashboard/companies/" + companyId + "/chats/" + chatId);
  };

  const getCompany = async (companyId: string) => {
    const currentToken = await getToken();
    await axios
      .get(`${hostname}/companies/${companyId}`, {
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
      })
      .then((res) => {
        setCompany(res.data.company);
        if (res.data.company.subscriptionType) {
          setCurrentPlan(res.data.company.subscriptionType);
        }
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      });
  };

  const getDocuments = async (
    path: string,
    selectedDoc: Document | undefined = undefined
  ) => {
    const currentToken = await getToken();
    await axios
      .get(`${hostname}/documents`, {
        headers: { Authorization: `Bearer ${currentToken}` },
        params: { path: path, companyId: companyId },
      })
      .then((res) => {
        var documentList = res.data;
        if (selectedDoc) {
          setDocuments([selectedDoc, ...documentList]);
        } else {
          setDocuments(documentList);
        }
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      });
  };

  const getData = async () => {
    const currentToken = await getToken();
    const empFuture = axios
      .get(`${hostname}/chats/${companyId}/${id}`, {
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
      })
      .then((res) => {
        var docFuture;
        const currentChat = res.data.chat;
        if (currentChat.settings) {
          var newSettings = { ...contextSettings, ...currentChat.settings };
          setContextSettings(newSettings);
          docFuture = getDocuments(newSettings.documentPath);
        } else {
          docFuture = getDocuments(folderPath);
        }
        return Promise.all([
          getEmployees(currentChat.employeeId),
          getCompany(currentChat.companyId),
          docFuture,
        ]);
      });

    const chatFuture = chatApi
      .getMessages(companyId, id)
      .then((messages: any) => {
        setChat(messages);
      });
    await Promise.all([empFuture, chatFuture]);
    setLoading(false);
  };

  useEffect(() => {
    if (currentUser.uid) {
      setInit(true);
      getData();
    }
  }, [id, currentUser]);

  const refreshChat = async (
    userMessage: Message,
    assistantMessage: Message
  ) => {
    await chatApi
      .getMessageContext(companyId, id, assistantMessage.id)
      .then((msgContext) => {
        updateContext(assistantMessage.id, msgContext);
      });
  };

  const sendMessage = async () => {
    if (prompt === "") return;
    chatApi.sendPyPrompt(
      prompt,
      chat,
      documents,
      handleAdd,
      handleAppendText,
      employee,
      { ...contextSettings, image: image, requestDocuments: requestDocuments },
      setPrompt,
      refreshChat,
      company,
      currentUser,
      id
    );
    setImage("");
    setRequestDocuments([]);
  };

  const findCodeBlock = (msg: string) => {
    var startBlock = msg.indexOf("```");
    if (startBlock !== -1) {
      // Move position 3 so we are past special indicator
      var startCodeBlock = startBlock + 3;
      const language = msg.slice(startCodeBlock).split("\n")[0];
      var endBlock = msg.indexOf("```", startCodeBlock);
      // Remove language from the code block and new line char
      if (language.length > 3) {
        startCodeBlock += 1 + language.length;
      }
      var endMsgBlock = "";
      if (endBlock !== -1) {
        endMsgBlock = msg.slice(endBlock + 3);
      }
      return (
        <span>
          {msg.slice(0, startBlock)}
          <CopyBlock
            customStyle={{ overflow: "scroll" }}
            language={language}
            text={msg.slice(startCodeBlock, endBlock)}
            showLineNumbers
            theme={dracula}
            wrapLines
            codeBlock
          />
          {findCodeBlock(endMsgBlock)}
        </span>
      );
    } else {
      return msg;
    }
  };

  const truncate = (input: string | undefined) => {
    if (!input) return "";
    if (input.length > 40) {
      return input.substring(0, 40) + "...";
    }
    return input;
  };

  const handleDocumentChange = (e: any) => {
    var newContextSettings = {
      ...contextSettings,
      documentScope: e.target.value,
    };
    setContextSettings(newContextSettings);
    const selectedDoc = documents.find((x) => x.id === e.target.value);
    if (e.target.value === "root") {
      setFolderPath("/");
      getDocuments("/");
    }
    if (e.target.value === "/shared") {
      setFolderPath("/shared");
      getDocuments("/shared");
    }
    if (!selectedDoc) {
      return;
    }
    if (selectedDoc.type === "folder") {
      const newPath =
        selectedDoc.path !== "/"
          ? `${selectedDoc.path}/${selectedDoc.name}`
          : `/${selectedDoc.name}`;
      setFolderPath(newPath);
      getDocuments(newPath, selectedDoc);
      setContextSettings({ ...newContextSettings, documentPath: newPath });
    }
  };

  const getContextText = (match: Memory): string => {
    const score = (match.score || 0).toFixed(2);
    return `Score: ${score}, ${truncate(match.content)}`;
  };

  const getBackgroundColor = (message: Message) => {
    if (message.role === "user") {
      return mode === "dark" ? "#222831" : "transparent";
    } else {
      return mode === "dark" ? "#2b323e" : theme.palette.background.paper;
    }
  };

  function SimpleDialog(props: SimpleDialogProps) {
    const { onClose, message, open } = props;

    const handleClose = () => {
      onClose(message);
    };

    if (!message) {
      return <div></div>;
    }

    return (
      <Dialog onClose={handleClose} open={open} scroll="paper">
        <DialogTitle>Message Context</DialogTitle>
        <DialogContent>
          {message.context.memories.map((m) => (
            <Accordion
              key={m.id}
              expanded={expanded === m.id}
              onChange={handleChange(m.id)}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography style={{ marginRight: 5 }}>{m.type}</Typography>
                <Typography sx={{ color: "text.secondary" }}>
                  {getContextText(m)}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{m.content}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setDialogOpen(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  const getTooltipBlock = (message: Message) => {
    if (
      !message.context ||
      !message.context.memories ||
      message.context.memories.length === 0
    ) {
      return;
    }
    return (
      <Typography variant="body1" component="div">
        <Button
          onClick={() => {
            setDialogSelectedMessage(message);
            setDialogOpen(true);
          }}
          size="small"
          style={{ marginLeft: 5, marginRight: 5, verticalAlign: -2 }}
        >
          Context
        </Button>
      </Typography>
    );
  };

  const handleFileChange = async (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const base64 = await fileToBase64(file);
      setImage(base64);
    }
  };

  const handleDocumentFileChange = async (event: any) => {
    const files = event.target.files;
    const formData = new FormData();
    for (var i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }
    const currentToken = await getToken();
    await axios
      .post(`${hostname}/documents/convert`, formData, {
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
      })
      .then((res: any) => {
        setRequestDocuments([...requestDocuments, ...res.data.documents]);
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      });
  };

  useEffect(() => {
    scrollToBottom();
  }, [chat]);

  if (loading) {
    return <LoadingScreen open={loading} />;
  }

  const handleDialogClose = (m: Message | undefined) => {
    setDialogOpen(false);
  };

  const settingsClose = () => {
    setSettingsOpen(false);
  };

  const saveSettings = () => {
    chatApi.saveChatSettings(id, contextSettings);
    setSettingsOpen(false);
  };

  const saveCompanyDefaultSettings = () => {
    chatApi.saveCompanyDefaultChatSettings(companyId, contextSettings);
    saveSettings();
  };

  const copyContent = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      eventListener({
        type: "SET_INFO",
        info: "Content copied to clipboard",
      });
    } catch (err) {
      eventListener({
        type: "SET_ERROR",
        error: "Error copying to clipboard",
      });
    }
  };

  const inputHeight = requestDocuments.length > 0 || image ? 445 : 388;

  return (
    <Box>
      <SelectEmployeeDialog
        open={selectEmployeesOpen}
        employees={employees}
        title="Select Employee"
        content="Choose an employee to chat with."
        buttonText="Submit"
        onClose={() => setSelectEmployeesOpen(false)}
        onSubmit={(employee: any) => {
          setSelectEmployeesOpen(false);
          createChat(employee);
        }}
      />
      <SimpleDialog
        message={dialogSelectedMessage}
        open={dialogOpen}
        onClose={handleDialogClose}
      />
      <Grid container spacing={0}>
        <Hidden mdDown>
          <Grid
            item
            md={2}
            sx={{
              borderRight:
                theme.palette.mode === "dark"
                  ? "1px solid rgba(255, 255, 255, 0.12)"
                  : "1px solid rgba(0, 0, 0, 0.12)",
            }}
          >
            <Button
              fullWidth
              sx={{ mt: 1, mb: 2 }}
              startIcon={<Add />}
              onClick={() => setSelectEmployeesOpen(true)}
            >
              New Chat
            </Button>
            <ChatLog
              ref={chatLogRef}
              style={{ maxHeight: "calc(100vh - 125px)", overflow: "scroll" }}
              companyId={companyId}
              getToken={getToken}
              currentUser={currentUser}
              currentChatId={id}
              perPage={25}
              eventListener={eventListener}
            />
          </Grid>
        </Hidden>
        <Grid item xs={12} md={10}>
          <Box sx={{ maxHeight: "calc(100vh - 60px)", overflow: "scroll" }}>
            <Hidden smDown>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: 15,
                  borderBottom:
                    theme.palette.mode === "dark"
                      ? "1px solid rgba(255, 255, 255, 0.12)"
                      : "1px solid rgba(0, 0, 0, 0.12)",
                }}
              >
                <Avatar
                  src={employee.picture}
                  style={{ height: 60, width: 60, marginRight: 10 }}
                />
                <Typography gutterBottom variant="h5" color="textSecondary">
                  {`${employee.name} (${employee.title})`}
                </Typography>
              </div>
            </Hidden>
            <Box
              sx={{
                height: `calc(100vh - ${inputHeight}px)`,
                overflow: "scroll",
                pl: 2,
                pr: 2,
              }}
            >
              <List>
                {!chat.length && <p>How can I help you today?</p>}
                {chat.map((message: Message, index: number) => (
                  <ListItem
                    key={message.id}
                    style={{
                      padding: "0px, auto",
                    }}
                  >
                    {getMessageAlign(message.role) === "right" && (
                      <div style={{ flex: "1" }}></div>
                    )}
                    <Card
                      style={{
                        float: getMessageAlign(message.role),
                        backgroundColor: getBackgroundColor(message),
                      }}
                    >
                      <CardHeader
                        style={{
                          float: getMessageAlign(message.role),
                          width: "100%",
                        }}
                        avatar={
                          <Avatar
                            alt={message.user}
                            sx={{ width: 30, height: 30 }}
                            src={message.picture}
                          />
                        }
                        title={
                          <div>
                            {message.user}
                            <IconButton
                              color="primary"
                              style={{
                                float: "right",
                                fontSize: 10,
                                marginLeft: 10,
                              }}
                              onClick={() => copyContent(message.text)}
                            >
                              <ContentCopyIcon />
                            </IconButton>
                          </div>
                        }
                        subheader={moment(new Date(message.ts)).fromNow()}
                      />
                      <CardContent>
                        {message.text.startsWith("```") && <p>Code Block</p>}
                        <ReactMarkdown
                          remarkPlugins={[remarkGfm]}
                          children={message.text}
                          components={{
                            code(props) {
                              const { children, className, node, ...rest } =
                                props;
                              const match = /language-(\w+)/.exec(
                                className || ""
                              );
                              var lang = "";
                              console.log(match);
                              if (
                                match &&
                                (match[1] == "sh" || match[1] == "bash")
                              ) {
                                match[1] = "shell";
                              }
                              if (
                                match &&
                                supportedLanguages.includes(match[1])
                              ) {
                                console.log("language match", match);
                                lang = match[1];
                              } else {
                                lang = "text";
                              }
                              return lang ? (
                                <CopyBlock
                                  language={lang}
                                  text={String(children).replace(/\n$/, "")}
                                  showLineNumbers
                                  theme={dracula}
                                  wrapLines
                                  codeBlock
                                />
                              ) : (
                                <code style={{ color: "#e8912d" }}>
                                  {children}
                                </code>
                              );
                            },
                          }}
                        />
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                          }}
                        >
                          {getTooltipBlock(message)}
                          <div style={{ flex: "1" }}></div>
                          <Typography variant="caption" color="textSecondary">
                            {countWords(message.text)} words
                          </Typography>
                        </div>
                      </CardContent>
                    </Card>
                  </ListItem>
                ))}
              </List>
              <Box ref={messagesEndRef} />
            </Box>
            <div
              style={{
                padding: 10,
                borderTop:
                  theme.palette.mode === "dark"
                    ? "1px solid rgba(255, 255, 255, 0.12)"
                    : "1px solid rgba(0, 0, 0, 0.12)",
              }}
            >
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  sendMessage();
                }}
              >
                <Grid container spacing={2}>
                  <Grid item md={12} xs={12}>
                    <TextField
                      multiline
                      minRows={4}
                      maxRows={4}
                      fullWidth
                      placeholder="Type question here (Shift+Enter=New Line, Enter=Send Message)"
                      value={prompt}
                      onChange={(e) => setPrompt(e.target.value)}
                      variant="outlined"
                      onKeyPress={(e) => {
                        if (e.key === "Enter" && !e.shiftKey) {
                          e.preventDefault();
                          sendMessage();
                        }
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {visionModels.has(contextSettings.model) && (
                              <React.Fragment>
                                <input
                                  accept="image/*"
                                  style={{ display: "none" }}
                                  id="icon-button-file"
                                  type="file"
                                  onChange={handleFileChange}
                                  onClick={(event: any) => {
                                    event.target.value = null;
                                  }}
                                />
                                <label htmlFor="icon-button-file">
                                  <IconButton
                                    color="primary"
                                    aria-label="upload picture"
                                    component="span"
                                  >
                                    <AddPhotoAlternateOutlined />
                                  </IconButton>
                                </label>
                              </React.Fragment>
                            )}
                            <React.Fragment>
                              <input
                                accept=".doc,.docx,.pdf,.csv,.xlsx,.xls"
                                style={{ display: "none" }}
                                id="icon-button-file"
                                type="file"
                                multiple
                                onChange={handleDocumentFileChange}
                                onClick={(event: any) => {
                                  event.target.value = null;
                                }}
                              />
                              <label htmlFor="icon-button-file">
                                <IconButton
                                  color="primary"
                                  aria-label="upload picture"
                                  component="span"
                                >
                                  <UploadFileOutlined />
                                </IconButton>
                              </label>
                            </React.Fragment>
                            <IconButton color="primary" type="submit">
                              <SendIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  {image && (
                    <Grid item xs={12}>
                      <Stack direction="row" spacing={2}>
                        <Chip
                          avatar={<Avatar src={image} />}
                          label={"Image"}
                          variant="outlined"
                          onDelete={() => setImage("")}
                        />
                      </Stack>
                    </Grid>
                  )}
                  {requestDocuments.length > 0 && (
                    <Grid item xs={12}>
                      <Stack direction="row" spacing={2}>
                        {requestDocuments.map((document) => (
                          <Chip
                            key={document.id}
                            icon={<DescriptionOutlined />}
                            label={document.name}
                            variant="outlined"
                            onDelete={() =>
                              setRequestDocuments(
                                requestDocuments.filter(
                                  (x) => x.id !== document.id
                                )
                              )
                            }
                          />
                        ))}
                      </Stack>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Button
                      style={{ height: 40, marginBottom: 10 }}
                      fullWidth
                      variant="outlined"
                      type="button"
                      onClick={() => setSettingsOpen(true)}
                    >
                      Settings
                      <TuneIcon style={{ marginLeft: 8 }} fontSize="small" />
                    </Button>
                    <Grid item xs={12} style={{ textAlign: "center" }}>
                      <Typography variant="caption">
                        Parallel AI can make mistakes. Consider checking
                        important information.
                      </Typography>
                    </Grid>
                    {/* {!recording && (
                      <Button
                        style={{ height: 40 }}
                        fullWidth
                        variant="outlined"
                        onClick={record}
                      >
                        Start Audio Meeting (Beta){" "}
                        <Mic style={{ marginLeft: 8 }} fontSize="small" />
                      </Button>
                    )}
                    {recording && (
                      <Button
                        style={{ height: 40 }}
                        fullWidth
                        variant="outlined"
                        onClick={stopRecording}
                      >
                        Stop Audio Meeting
                      </Button>
                    )} */}
                  </Grid>
                  <Grid>
                    <Dialog
                      open={settingsOpen}
                      onClose={(e) => {
                        setSettingsOpen(false);
                      }}
                    >
                      <DialogTitle>Settings</DialogTitle>
                      <DialogContent>
                        <DialogContentText>External Sources</DialogContentText>
                        <FormControlLabel
                          control={
                            <Switch
                              onChange={(e) => {
                                setContextSettings({
                                  ...contextSettings,
                                  // @ts-ignore
                                  documents: e.target.checked,
                                });
                              }}
                              checked={contextSettings.documents}
                            />
                          }
                          label="Documents"
                        />
                        <FormControlLabel
                          control={
                            <Switch
                              onChange={(e) => {
                                setContextSettings({
                                  ...contextSettings,
                                  // @ts-ignore
                                  searchEngine: e.target.checked,
                                });
                              }}
                              checked={contextSettings.searchEngine}
                            />
                          }
                          label="Search Engine Results"
                        />
                        <FormControlLabel
                          control={
                            <Switch
                              onChange={(e) => {
                                setContextSettings({
                                  ...contextSettings,
                                  // @ts-ignore
                                  websitesJs: e.target.checked,
                                });
                              }}
                              checked={contextSettings.websitesJs}
                            />
                          }
                          label="Websites"
                        />
                        <DialogContentText style={{ marginTop: 10 }}>
                          Memory Recall
                        </DialogContentText>
                        <FormControlLabel
                          control={
                            <Switch
                              onChange={(e: any) => {
                                setContextSettings({
                                  ...contextSettings,
                                  longTermMemories: e.target.checked,
                                });
                              }}
                              checked={contextSettings.longTermMemories}
                            />
                          }
                          label="Long-Term Memories"
                        />
                        <FormControlLabel
                          control={
                            <Switch
                              onChange={(e: any) => {
                                setContextSettings({
                                  ...contextSettings,
                                  shortTermMemories: e.target.checked,
                                });
                              }}
                              checked={contextSettings.shortTermMemories}
                            />
                          }
                          label="Short-Term Memories"
                        />
                        <DialogContentText style={{ marginTop: 10 }}>
                          System Persona
                        </DialogContentText>
                        <FormControlLabel
                          control={
                            <Switch
                              onChange={(e) => {
                                setContextSettings({
                                  ...contextSettings,
                                  // @ts-ignore
                                  employee: e.target.checked,
                                });
                              }}
                              checked={contextSettings.employee}
                            />
                          }
                          label="Employee Context"
                        />
                        <FormControlLabel
                          control={
                            <Switch
                              onChange={(e) => {
                                setContextSettings({
                                  ...contextSettings,
                                  // @ts-ignore
                                  company: e.target.checked,
                                });
                              }}
                              checked={contextSettings.company}
                            />
                          }
                          label="Company Context"
                        />
                        <ModelSelector
                          currentPlan={currentPlan}
                          value={contextSettings.model}
                          onChange={(e: any) =>
                            setContextSettings({
                              ...contextSettings,
                              model: e.target.value,
                            })
                          }
                        />
                        {contextSettings.longTermMemories && (
                          <FormControl fullWidth style={{ marginTop: 20 }}>
                            <InputLabel>Long-Term Memory Scope</InputLabel>
                            <Select
                              label="Long-Term Memory Scope"
                              value={contextSettings.memoryScope}
                              onChange={(e: any) =>
                                setContextSettings({
                                  ...contextSettings,
                                  memoryScope: e.target.value,
                                })
                              }
                            >
                              <MenuItem value="chat">Current Chat</MenuItem>
                              <MenuItem value="all">All Chats</MenuItem>
                            </Select>
                          </FormControl>
                        )}
                        <FormControl fullWidth style={{ marginTop: 20 }}>
                          <InputLabel>Strategy</InputLabel>
                          <Select
                            label="Strategy"
                            value={contextSettings.strategy}
                            onChange={(e: any) =>
                              setContextSettings({
                                ...contextSettings,
                                strategy: e.target.value,
                              })
                            }
                          >
                            <MenuItem value="one-shot">
                              One-Shot (Fastest)
                            </MenuItem>
                            <MenuItem value="chain">
                              Function Chain (Most Accurate)
                            </MenuItem>
                            <MenuItem value="langchain">ReAct (Beta)</MenuItem>
                          </Select>
                        </FormControl>
                        {contextSettings.documents && (
                          <FormControl fullWidth style={{ marginTop: 20 }}>
                            <InputLabel>Document Scope</InputLabel>
                            <Select
                              value={contextSettings.documentScope}
                              label="Document Scope"
                              onChange={handleDocumentChange}
                            >
                              <MenuItem key={"tags"} value={"tags"}>
                                <IconMenuItem
                                  name={"Document Tags"}
                                  icon={<SellIcon />}
                                />
                              </MenuItem>
                              <MenuItem key={"root"} value={"root"}>
                                <IconMenuItem
                                  name={"/"}
                                  icon={<SourceIcon />}
                                />
                              </MenuItem>
                              <MenuItem key={"shared"} value={"/shared"}>
                                <IconMenuItem
                                  name={company.name + " Shared"}
                                  icon={<SourceIcon color="primary" />}
                                />
                              </MenuItem>
                              {documents.map((doc) => (
                                <MenuItem key={doc.id} value={doc.id}>
                                  <IconMenuItem
                                    name={doc.name}
                                    icon={
                                      doc.type === "folder" ? (
                                        <SourceIcon />
                                      ) : (
                                        <Description />
                                      )
                                    }
                                  />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                        {contextSettings.documents && (
                          <FormControl fullWidth style={{ marginTop: 20 }}>
                            <InputLabel>Document Strategy</InputLabel>
                            <Select
                              value={contextSettings.documentStrategy}
                              label="Document Strategy"
                              onChange={(e) =>
                                setContextSettings({
                                  ...contextSettings,
                                  documentStrategy: e.target.value,
                                })
                              }
                            >
                              <MenuItem value="similarity-ranking">
                                Similarity Ranking
                              </MenuItem>
                              <MenuItem value="full-documents">
                                Full Documents
                                <Chip
                                  label="+5 Credits"
                                  style={{ marginLeft: 5 }}
                                />
                              </MenuItem>
                            </Select>
                          </FormControl>
                        )}
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={settingsClose}>Cancel</Button>
                        <Button onClick={saveCompanyDefaultSettings}>
                          Save As Default
                        </Button>
                        <Button onClick={saveSettings} variant="outlined">
                          Save
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Grid>
                </Grid>
              </form>
            </div>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
